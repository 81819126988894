.Checkbox{
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
}

.Checkbox .box{
    display: block;
    height: 24px;
    width: 24px;
    border: 1px var(--border) solid;
    border-radius: 5px;
    margin-right: 8px;
    transition: all .3s ease;
}

.Checkbox.selected .box{
    background-color: var(--primary);
    border-color: var(--primary);   
}

.Checkbox .box::after{
    display: block;
    height: 22px;
    width: 22px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(45deg);
    opacity: 0;
    transition: transform .2s ease;
}

.Checkbox.Checkbox.selected .box::after{
    transform: none;
    opacity: 1;
}

.Checkbox span{
    font-size: 14px;
    line-height: 1.2;
}