.Login {
  margin: auto;
  display: flex;
}

.plate {
  width: 50%;
  width: 100%;
}

.plate h1 {
  font-size: 24px;
  margin: 0 0 24px 0;
}

.plate .main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 40px;
  max-width: 400px;
  margin-left: auto;
  width: 100%;
}

.brand{
  display: flex;
  align-items: center;
}

.brand span{
  width: calc(100% - 44px);
  margin-left: 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3px;
  color: var(--text);
}


.picture {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='120' height='120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill-opacity='.18' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.field {
  margin-bottom: 16px;
}

.Login form {
  width: 100%;
}

.link {
  display: block;
  width: fit-content;
  font-size: 14px;
  margin-top: 12px;
}

.Login footer {
  font-size: 14px;
  margin-top: 24px;
  color: var(--textSecondary);
}

.pattern{
  height: 100%;
  width: 100%;
  background-image: url("../Assets/pattern.png");
  background-size: 200px;
  opacity: .5;
}

@media (max-width: 768px){
  .picture{
    display: none;
  }
  .plate{
    width: 100%;
  }
  .plate .main {
    margin: auto;
  }
}