.Card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px var(--border) solid;
    border-radius: 7px;
    padding: 12px;
    color: inherit!important;
    cursor: pointer;
    transition: box-shadow .2s ease;
}

.Card:hover{
    box-shadow: 0 4px 12px -4px rgba(0,0,0,.14);
}

.courseTitle{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.courseDescription{
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 8px;
    color: var(--textSecondary);
}