.Chat{
    position: fixed;
    bottom: 24px;
    right: 24px;
}

.fab{
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    transition: transform .2s ease;
    margin-left: auto;
    margin-top: 16px;
}

.close{
    position: absolute;
    opacity: 0;
    transform: scale(.5);
}

.close, .icon{
    transition: transform .2s ease, opacity .2s ease;
}

.icon{
    opacity: 1;
    transform: none;
}

.fab.open .close{
    opacity: 1;
    transform: none;
}

.fab.open .icon{
    opacity: 0;
    transform: scale(.5);
}

.fab:active{
    transform: scale(0.95);
}

.header{
    padding: 8px 16px;
    background-color: var(--primary);
    color: #ffffff;
}

.window{
    position: fixed;
    background-color: var(--back);
    border-radius: 13px;
    overflow: hidden;
    max-width: 320px;
    width: 100%;
    box-shadow: 0 0 0 1px var(--border);
    pointer-events: none;
    right: 24px;
    bottom: 96px;
    opacity: 0;
    transform: translateY(-100px);
    transition: transform .2s ease, opacity .2s ease;
}

.window.open{
    pointer-events: all;
    opacity: 1;
    transform: none;
}

.messages{
    height: 360px;
    overflow-y: auto;
    padding: 8px 12px;
    -webkit-overflow-scrolling: touch;
}

.message{
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
}

.message.me{
    flex-direction: row-reverse;
}

.message.me .messageText{
    margin-left: auto;
}

.avatar{
    height: 32px;
    width: 32px;
    margin-right: 12px;
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--border);
    color: var(--textSecondary);
    background-size: cover;
}

.message.me .avatar{
    margin-right: 0;
    margin-left: 12px;
}

.message.me .messageDate{
    text-align: right;
}

.messageBody{
    width: calc(100% - 44px);
}

.messageText{
    background-color: var(--border);
    width: fit-content;
    border-radius: 7px;
    padding: 8px;
    font-size: 15px;
    line-height: 1.2;
    overflow-wrap: break-word;
    max-width: 100%;
}

.messageName{
    font-size: 13px;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 4px;
}

:global(.dark) .messageName{
    color: var(--primaryLighter);
}

.messageDate{
    color: var(--textSecondary);
    font-size: 12px;
    margin-top: 4px;
}

.toolbar{
    display: flex;
    align-items: stretch;
    border-top: 1px var(--border) solid;
}

.toolbar input{
    display: block;
    appearance: none;
    border: none;
    background-color: transparent;
    color: var(--text);
    font-size: 16px;
    width: calc(100% - 32px);
    padding: 10px 18px;
    outline: none!important;
}

.toolbar button{
    display: block;
    outline: none!important;
    appearance: none;
    border: none;
    padding: 0;
    width: 64px;
    color: var(--textSecondary);
    background-color: transparent;
    transition: transform .2s ease;
}

.toolbar button:active{
    transform: scale(0.9);
}

@media (max-width: 768px){
    .messages{
        height: calc(100vh - 260px);
    }
    .window{
        max-width: calc(100vw - 48px);
        margin-left: auto;
    }
}