/*.results table {
  white-space: nowrap;
  font-size: 14px;
}

.results table th {
  text-transform: uppercase;
  color: var(--textSecondary);
}

.results table th,
.results table td {
  padding: 6px 8px;
  font-weight: bold;
}

.results .right {
  color: var(--green);
}

.results .wrong {
  color: var(--red);
}

.results tr {
  border-bottom: 1px solid var(--border);
}

.cell {
  border-left: 1px solid var(--border);
  text-align: center;
  text-transform: uppercase;
}*/

/* NEW */

.table {
  font-size: 14px;
  font-weight: bold;
  width: 1024px;
  max-width: 100%;
  max-height: 400px;
}

.table .th,
.table .td {
  padding: 5px;
  border-bottom: 1px solid var(--border);
  border-left: 1px solid var(--border);
  background-color: var(--back);
  overflow: hidden;
}

.table .th {
  color: var(--textSecondary);
  font-size: 12px;
  text-transform: uppercase;
}

.table .td:first-child,
.table .th:first-child {
  padding-left: 24px;
  border-right: 1px solid var(--border);
}

.table .td:first-child + .td,
.table .th:first-child + .th {
  border-left: unset;
}

.table .tr .td:last-child,
.table .th:last-child {
  border-right: unset;
}

.table .tr:last-child .td {
  border-bottom: unset;
}

.table .tr .td:last-child {
  color: var(--textSecondary);
}

.table.sticky {
  overflow: scroll;
}
.table.sticky .header,
.table.sticky .footer {
  position: sticky;
  z-index: 1;
  width: fit-content;
}
.table.sticky .header {
  top: 0;
}
.table.sticky .body {
  position: relative;
  z-index: 0;
}
.table.sticky [data-sticky-td] {
  position: sticky;
}

.cell {
  text-transform: uppercase;
  width: fit-content;
  text-align: center;
  width: 100%;
}
