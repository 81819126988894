.ready{
    min-height: calc(100vh - 96px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.ready button{
    width: fit-content;
    margin: auto;
}

.ready p{
    max-width: 360px;
    font-size: 14px;
    line-height: 1.2;
    color: var(--textSecondary)
}

.card{
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid
}

@media (max-width: 900px){
    .ready{
        min-height: calc(100vh - 192px);
    }
}

.timer {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.Variant{
    display: flex;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
}

.Variant:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Variant span{
    width: calc(100% - 32px);
    font-size: 14px;
    color: var(--textSecondary);
    transition: color .2s ease;
}

.dot{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px var(--border) solid;
}

.dot::after{
    content: "";
    display: block;
    height: 12px;
    width: 12px;
    background-color: var(--primary);
    border-radius: 50%;
    transform: scale(.5);
    opacity: 0;
    transition: transform .2s ease, opacity .2s ease;
}

.selected.dot::after{
    transform: none;
    opacity: 1;
}

.selected + span{
    color: var(--text)!important;
}

.Toolbar{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Toolbar button{
    width: fit-content;
}

.Toolbar .arrowButton{
    padding: 0;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--primary);
    color: #ffffff;
    width: 32px;
    height: 32px;
    outline: none;
    border-radius: 50%;
    transition: transform .2s ease;
    cursor: pointer;
}

.Toolbar .arrowButton:disabled{
    background-color: var(--border);
    color: var(--textSecondary)
}

.Toolbar .arrowButton:active{
    transform: scale(.95);
}

.arrowButton svg{
    display: block;
}

.variants{
    margin-top: 8px;
}

.contentTitle{
    font-size: 15px;
    color: var(--textSecondary);
}

.Question img{
    max-width: 100%;
    border-radius: 7px;
}

.Question audio{
    width: 100%;
    outline: none;
}

.contentDescription{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.fab{
    position: fixed;
    right: 24px;
    top: 24px;
    padding: 0 16px;
    background-color: var(--primary);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    outline: none!important;
    transition: transform .2s ease;
}

.fab:active{
    transform: scale(.96);
}

.fab svg{
    display: block;
    margin-right: 8px;
}