.image{
    min-height: 240px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 0 0 1px var(--border) inset;
}

.sliderWrap{
    position: relative;
    box-shadow: 0 0 0 1px var(--border);
    border-radius: 7px;
    overflow: hidden;
}

.sliderWrap button{
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 16px;
    appearance: none;
    background-color: transparent;
    height: 100%;
    top: 0;
    color: var(--primary);
    border: none;
    z-index: 10;
    cursor: pointer;
    outline: none;
}

.sliderWrap button:disabled{
    color: var(--border);
}

.sliderWrap button svg{
    display: block;
}

.sliderWrap .prev{
    left: 0;
}

.sliderWrap .next{
    right: 0;
}

.dots{
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    z-index: 10;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.08));
}

.dot{
    margin: 16px 8px;
    cursor: pointer;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--back);
    border: 1px var(--border) solid;
}

.dot.active{
    background-color: var(--primary);
    border: 1px var(--primary) solid;
}

.slider{
    display: flex;
    transition: transform .2s ease;
}

.slider .image{
    box-shadow: none;
    flex-grow: 1;
    border-radius: 0px;
}

.title{
    font-weight: 600;
}

.description{
    margin: 4px 0 12px 0;
    font-size: 14px;
    color: var(--textSecondary);
}

.price{
    font-size: 20px;
    margin: 12px 0 4px 0;
    line-height: 1.15;
}

.price span{
    margin-left: 4px;
    color: var(--textSecondary);
    font-size: 14px;
}

.buttons{
    display: flex;
    align-items: center;
}

.buttons button:not(:last-of-type){
    margin-right: 8px;
}