.Drawer{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    justify-content: space-between;
    padding: 24px;
    box-shadow: 1px 0 0 0 var(--border);
    position: fixed;
    overflow: auto;
    background-color: var(--back);
    -webkit-overflow-scrolling: touch;
    z-index: 24;
}

.brand{
    margin-left: 12px;
    display: flex;
    align-items: center;
}

.brand span{
    width: calc(100% - 44px);
    margin-left: 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
}

.Drawer a {
    text-decoration: none!important;
}

.menu{
    padding: 24px 0;
}

.copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: var(--textSecondary);
}

.copyright a {
    color: inherit
}

.backdrop{
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: 23;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.2);
    transition: opacity .2s ease;
}

:global(.dark) .backdrop{
    background-color: rgba(0,0,0,.5);
}

.backdrop.open{
    opacity: 1;
    pointer-events: all;  
}

.burger{
    display: none;
    background-color: var(--primary);
    padding: 16px 12px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 25;
    left: 24px;
    transition: left .2s ease, transform .2s ease;
    box-shadow: 0 8px 16px -4px rgba(0,0,0,.3);
}

.burger:active{
    transform: scale(.9);
}

.burger.open{
    left: 250px;
}

.burger>div{
    width: 22px;
    height: 2px;
    background-color: #fff;
    transition: transform .2s ease, opacity .2s ease;
}

.burger>div:not(:last-child){
    margin-bottom: 4px;
}

.burger.open>div{
    opacity: 0;
}

.burger.open>div:first-child{
    opacity: 1;
    transform: translateY(6px) rotate(45deg);
}

.burger.open>div:last-child{
    opacity: 1;
    transform: translateY(-6px) rotate(-45deg);
}

@media (max-width: 900px){
    .brand{
        padding: 6px 0;
    }
    .backdrop{
        display: block;
    }
    .Drawer{
        transform: translateX(-100%);
        transition: transform .2s ease;
    }
    .Drawer.open{
        transform: none;
    }
    .burger{
        position: fixed;
        top: 24px;
        left: 24px;
        display: block;
    }
}