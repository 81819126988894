.Pay{
    padding: 48px 0;
}

.container{
    max-width: 380px;
    margin: auto;
    padding: 0 24px;
}

.brand{
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.brand span{
    max-width: calc(100% - 44px);
    margin-left: 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
    color: var(--text);
}

.brand + p{
    text-align: center;
    font-size: 14px;
    margin: 12px 0 0 0;
    color: var(--textSecondary)
}

.back{
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    font-size: 14px;
}

.back svg{
    display: block;
    margin-right: 8px;
}

.Pay form{
    margin-top: 24px;
}

.result{
    padding: 24px 0;
    margin: 24px 0;
    border-top: 1px var(--border) solid;
}

.debt{
    margin-top: 12px;
    font-size: 14px;
}

.sum p{
    font-size: 12px;
    line-height: 1.2;
    color: var(--textSecondary);
    margin: 12px 0;
    text-align: center;
}